<template>
  <div class="fifth">
    <br>
    <ATitle text="第五届回顾" icon="6"></ATitle>
    <br>
    <div class="first-info center tc">
      <div class="prv">
        <div class="fl">
          <video poster="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/宣传片封面.jpg" controls="controls" src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/宣传片.m4v"></video>
        </div>
        <div class="flex info">
          <div class="item"><h1>70000㎡</h1><p>展馆总面积</p></div>
          <div class="item"><h1>248家</h1><p>参展企业</p></div>
          <div class="item"><h1>469件</h1><p>参展展品</p></div>
          <div class="item"><h1>51个</h1><p>完成签约</p></div>
          <div class="item"><h1>588.3亿元</h1><p>签约金额</p></div>
          <div class="item"><h1>413万人次</h1><p>入场观众</p></div>
        </div>
      </div>
    </div>
    <CTitle text="开幕式主旨论坛"></CTitle>
    <div class="f-swiper"><router-link tag="a" to="/forum5?thread=0"><Swiper baseUrl="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/2、开幕式暨主旨论坛（照片）/" :list="mainImgList"></Swiper></router-link></div>
    <br>
    <CTitle text="主题论坛"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/forum5?thread=1" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/317008981416755-16X9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">“践行可持续发展 迈向未来绿色交通”高端对话</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=2" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/717008978543216-16X9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">浙江未来交通科创中心第三届科技成果推介会-主旨论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=3" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/317008977421355-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">航运服务创新发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=4" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/117008977888286-%E6%B0%91%E8%88%AA%E6%9C%BA%E5%9C%BA%E7%BD%91%E7%AB%99%E5%9B%BE-01.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">民航智慧机场创新发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=5" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008977177330-16X9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">轨道交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=6" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008977027261-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">第一届绿色物流与供应链发展大会开幕式暨主论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=7" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/417008976677800-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">第四届未来交通创新发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=8" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/317008980524474-1.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">浙江未来交通科创中心第三届科技成果推介会-成果推介活动</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=9" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008976423550-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">“双碳引领，赋能绿色供应链未来”分论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=10" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008976154163-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">“绿色循环，驱动物流+制造深度融合”分论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=11" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/917008975506074-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">“标准先行，助力行业高质量发展”分论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=12" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008975696081-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">“创新驱动，绿色供应链物流国际化科技创新”分论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=13" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/617008977668436-1.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">交通智能建养与低碳发展国际科技合作论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=14" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/117008976846148-16X9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">第四届综合交通创新创业大赛总决赛暨颁奖仪式</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=15" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/917008978747907-16X9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">中国（浙江）车联网产业发展创新论坛</div>
        </router-link>
        <router-link tag="a" to="/forum5?thread=16" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/617008981656890-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">绿色物流大讲堂暨“双碳”人才培养论坛</div>
        </router-link>
        <div class="forum-item" style="cursor: default;" />
        <div class="forum-item" style="cursor: default;" />
      </div>
    </div>
    <CTitle text="博览会照片"></CTitle>
    <div class="elevator-wrap center">
      <div class="fl elevator" style="height: 400px;">
        <div class="flex item" @click="cur=0" :class="cur==0&&'cur'">轨道交通馆</div>
        <div class="flex item" @click="cur=1" :class="cur==1&&'cur'">港航物流馆</div>
        <div class="flex item" @click="cur=2" :class="cur==2&&'cur'">智慧航空馆</div>
        <div class="flex item" @click="cur=3" :class="cur==3&&'cur'">绿色低碳馆</div>
        <div class="flex item" @click="cur=4" :class="cur==4&&'cur'">数字交通馆</div>
      </div>
      <div class="sponsor-list">
        <SwiperNode :cur="cur" :imgList="imgList"></SwiperNode>
      </div>
    </div>
    <div class="annual-elevator">
      <div class="annual cur">第五届</div>
      <router-link tag="div" class="annual" to="/fourth">第四届</router-link>
      <router-link tag="div" class="annual" to="/third">第三届</router-link>
      <router-link tag="div" class="annual" to="/second">第二届</router-link>
      <router-link tag="div" class="annual" to="/first">第一届</router-link>
    </div>
  </div>
</template>
<script>
import Swiper from '@/components/Swiper'
import SwiperNode from '@/components/SwiperNode'
export default {
  components: {
    Swiper,
    SwiperNode
  },
  data() {
    return {
      cur: 0,
      mainImgList: ['1N0A6166.JPG','2J4A5214.jpg','462A1412.jpg','微信图片_20231206164618.jpg','H05A2102.jpg','LK7A1616.jpg'],
      imgList: [
        {
          name: '轨道交通馆',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/4、五大主题馆照片/1、轨道交通馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area1.png',
          img: [['342A7957.JPG','342A7959.JPG','342A7972.JPG','342A8867.JPG','342A8942.JPG','H05A2458.jpg','H05A2515.jpg','H05A2539.jpg','H05A2545.jpg']]
        },
        {
          name: '港航物流馆',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/4、五大主题馆照片/2、港航物流馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area3.png',
          img: [['2J4A5918.jpg','2J4A5926.jpg','2J4A5939.jpg','2J4A5997.jpg','2J4A6046.jpg','R3AS0014.JPG','R3AS0036.JPG','R3AS0089.JPG','R3AS0170.jpg']]
        },
        {
          name: '智慧航空馆',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/4、五大主题馆照片/3、智慧航空馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area4.png',
          img: [['2J4A6023.jpg','2J4A6219.jpg','2J4A6284.jpg','R3AS0240.jpg','R3AS0357.jpg','R3AS0381.jpg','R3AS0384.jpg','R3AS0431.jpg','R3AS0357.jpg']]
        },
        {
          name: '绿色低碳馆',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/4、五大主题馆照片/4、绿色低碳馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area7.png',
          img: [['462A1821.jpg','462A1829.jpg','462A1832.jpg','462A1843.jpg','R3AS0568.jpg','R3AS0574.jpg','R3AS0585.jpg','R3AS0587.jpg','462A1832.jpg']]
        },
        {
          name: '数字交通馆',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/4、五大主题馆照片/5、数字交通馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area6.png',
          img: [['342A8045.JPG','342A8474.JPG','342A8498.JPG','342A8962.JPG','H05A2607.jpg','H05A2682.jpg','H05A2724.jpg','H05A2734.jpg','H05A2750.jpg']]
        },
      ],
    }
  }
}
</script>

<style lang="less" scoped>
video {
  background-color: #000000;
}
.fifth {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  .first-info {
    .prv {
      margin: 20px 0;
      height: 240px;
      .fl {
        width: 423px;
        height: 238px;
        video {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
        }
      }
      .info {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 50px;
        .item {
          text-align: center;
          height: 120px;
          width: 200px;
          padding: 20px 0;
          color: #125FAD;
          h1 {
            font-size: 28px;
          }
          p {
            color: #666;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .f-swiper {
    background: #F3F3F3;
    padding: 40px 0;
  }
  .forum-outer {
    background: url('../assets/maze_bg.svg') repeat;
    padding: 20px 0;
  }
  .forum-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
    .forum-item {
      display: block;
      cursor: pointer;
      width: 310px;
      height: 250px;
      border-radius: 4px;
      margin-bottom: 30px;
    }
    .img {
      width: 310px;
      height: 170px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      img {
        max-height: 170px;
        width: auto;
        transition: all ease-out 0.2s;
      }
    }
    .forum-item:hover {
      img {
        transform: scale(1.05);
      }
    }
    .text {
      height: 60px;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border-top: 1px solid #F0F0F0;
      line-height: 60px;
      background: #F0F0F0;
    }
  }
  .elevator-wrap {
    .elevator {
      border-radius: 4px;
      width: 90px;
      height: 482px;
      left: 10px;
      border: 1px solid #D6D6D6;
      background: #FFF;
      div {
        margin: 0 auto;
      }
      .item {
        cursor: pointer;
        height: 80px;
        width: 90px;
        border-bottom: 1px solid #D6D6D6;
        color: #666666;
        font-size: 16px;
        font-weight: 400px;
        text-align: center;
      }
      .item:last-child {
        border-bottom: none;
      }
      .cur {
        background: #2585E5;
        color: #FFF;
      }
    }
  }
  .sponsor-list {
    padding-left: 110px;
  }
}
.annual-elevator {
  position: fixed;
  right: calc(50% - 640px);
  top: 20%;
  border: 1px solid #D6D6D6;
  background: #FFF;
  .annual {
    cursor: pointer;
    height: 50px;
    width: 80px;
    border-bottom: 1px solid #D6D6D6;
    color: #666666;
    font-size: 16px;
    line-height: 50px;
    font-weight: 400px;
    text-align: center;
  }
  .annual:last-child {
    border-bottom: none;
  }
  .cur {
    background: #2585E5;
    color: #FFF;
  }
}
</style>
